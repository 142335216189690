import * as validationFunc from "vuelidate/lib/validators";

import { mapGetters } from "vuex";

export const formBuilderMixin = {
  data() {
    return {
      //***GET THESE LANGUAGES FROM VUEX STATE INSTEAD OF HARDCODED HERE, THIS IS TEMPORARY***
      form: {}
    };
  },
  created: function() {},
  computed: {
    ...mapGetters(["getLanguages", "getTabLanguage"])
  },
  methods: {
    generateFormOutOfSchemaJson(schemaJson, myModel = {}) {
      let vm = this;
      schemaJson["fields"].forEach(function(item) {
        if (vm.checkIfItemTranslatable(item)) {
          vm.getLanguages.forEach(function(lang) {
            vm.$set(
              vm.form,
              item.model + ":" + lang.lang,
              typeof item.default !== "undefined"
                ? item.default
                : typeof myModel[item.model + ":" + lang.lang] !== "undefined"
                ? myModel[item.model + ":" + lang.lang]
                : ""
            );
          });
        } else {
          vm.$set(
            vm.form,
            item.model,
              typeof myModel[item.model] !== "undefined"
              ? myModel[item.model]
              : typeof item.default !== "undefined"
              ? item.default
              : ""
          );
        }
      });
    },
    generateFormValidationsOutOfSchemaJson(schemaJson) {
      let vm = this;
      let formValidation = { form: {} };
      schemaJson["fields"].forEach(function(item) {
        if (vm.checkIfItemTranslatable(item)) {
          vm.getLanguages.forEach(function(lang) {
            formValidation.form[
              item.model + ":" + lang.lang
            ] = vm.setItemValidations(item.validations);
          });
        } else {
          formValidation.form[item.model] = vm.setItemValidations(
            item.validations
          );
        }
      });
      return formValidation;
    },
    setItemValidations(validations) {
      // console.log(validations);
      var itemValidationRules = {};

      for (const [key, value] of Object.entries(validations)) {
        itemValidationRules[key] = this.setValidationRule(key, value);
      }

      return itemValidationRules;
    },
    setValidationRule(key, value) {
      var validationRule;
      switch (key) {
        case "required":
          validationRule = validationFunc.required;
          break;
        case "email":
          validationRule = validationFunc.email;
          break;
        case "minLength":
          validationRule = validationFunc.minLength(value);
          break;
        case "maxLength":
          validationRule = validationFunc.maxLength(value);
          break;
        case "numeric":
          validationRule = validationFunc.numeric;
          break;
        case "decimal":
          validationRule = validationFunc.decimal;
          break;
        case "minValue":
          validationRule = validationFunc.minValue(value);
          break;
        case "maxValue":
          validationRule = validationFunc.maxValue(value);
          break;
        default:
          validationRule = validationFunc.required;
        // code block
      }
      return validationRule;
    },
    checkIfItemTranslatable(item) {
      return item.translatable && item.translatable;
    }
  },
  validations() {
    return this.generateFormValidationsOutOfSchemaJson(this.schemaJson);
  }
};
