<template>
  <div class="mb-5">
    <ul class="nav nav-tabs">
      <li
        class="nav-item nav-link pointer"
        v-for="(lang, langIndex) in getLanguages"
        v-bind:key="'tab-language-' + langIndex"
        :class="getTabLanguage.lang == lang.lang ? 'active' : ''"
        href="#"
        v-on:click="onTabLanguageChanged(lang)"
      >
        <span
          v-if="checkIfLangTabHasError(lang.lang)"
          class="text-danger nav-icon"
        >
          <i class="flaticon-exclamation-1 text-danger"></i>
        </span>
        <span class="nav-icon">
          <img alt="" class="h-20px w-20px rounded-sm" :src="lang.flag" />
        </span>
        <span>{{ lang.lang }}</span>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
<script>
import { SET_TAB_LANGUAGE } from "@/core/services/store/lang.module";
import { mapGetters } from "vuex";
export default {
  name: "FormTabLanguages",
  props: {
    /**
     * Vuelidate state $v
     */
    v: {}
  },
  data() {
    return {
      tabErrors: []
    };
  },
  components: {},
  mounted() {},
  watch: {
    v: {
      deep: true,

      // We have to move our method to a handler field
      handler() {
        let vm = this;
        vm.tabErrors = [];
        if (vm.v.$anyDirty == true) {
          for (const [key, value] of Object.entries(vm.v.form.$model)) {
            if (key.includes(":")) {
              //translatable
              var keyLang = key.split(":")[1];
              if (vm.v.form[key] && vm.v.form[key].$invalid == true) {
                if (!vm.tabErrors.includes(keyLang)) {
                  vm.tabErrors.push(keyLang);
                }
              }
            } else {
              //plain
              if (vm.v.form[key] && vm.v.form[key].$invalid == true) {
                if (!vm.tabErrors.includes("all")) {
                  vm.tabErrors.push("all");
                }
              }
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(["getLanguages", "getTabLanguage"])
  },
  methods: {
    onTabLanguageChanged(lang) {
      // this.tabLanguage = lang;
      this.$store.commit(SET_TAB_LANGUAGE, lang);
    },
    checkIfLangTabHasError(lang) {
      let vm = this;
      let hasError = false;
      if (vm.tabErrors.includes("all") || vm.tabErrors.includes(lang)) {
        hasError = true;
      }
      return hasError;
    }
  }
};
</script>
