<template>
  <div class="mb-2">
    <b-form-datepicker
      format="YYYY-MM-DD"
      :placeholder="i18n ? $t(i18n.placeholder) : null"
      :value="value"
      locale="bg"
      :date-format-options="{
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }"
      @input="setValue"
      :max="maxToday ? today : max"
      v-bind="$attrs"
      reset-button
      :start-weekday="1"
    ></b-form-datepicker>
    <span v-if="validateState === false" class="text-danger">
      {{ $t("VALIDATION.INVALID", { name: "" }) }}
    </span>
  </div>
</template>

<script>
export default {
  name: "FormInput",
  inheritAttrs: false,
  props: {
    /**
     * Validation id of the error
     */
    feedback_id: String,
    /**
     * name of the input
     */
    name: String,
    /**
     * form helper description
     */
    description: String,
    /**
     * max date
     */
    max: {
      type: String,
      default: null,
    },
    /**
     * Validation id of the error
     */
    feedback_id: {
      type: String,
      default: "",
    },
    /**
     * All Translation i18n keys used
     */
    i18n: {
      type: Object,
      default: () => ({}),
    },
    /**
     * All validations rules and params
     */
    validations: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Validate State validation
     */
    validateState: {
      type: Boolean,
      default: null,
    },
    value: {},
    maxToday: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      today: new Date().toISOString().slice(0, 10),
    };
  },
  methods: {
    setValue(value) {
      //  trigger a mutation, or dispatch an action
      this.$emit("input", value);
    },
  },
};
</script>
