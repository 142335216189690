<template>
  <div>
    <b-form-textarea
      :value="value"
      @input="setValue"
      v-bind="$attrs"
      :placeholder="$t(i18n.placeholder)"
      :state="validateState"
      rows="3"
      max-rows="8"
      no-auto-shrink
    ></b-form-textarea>
    <b-form-invalid-feedback
      v-if="i18n.validation"
      :id="'invalid-' + feedback_id"
    >
      {{ $t(i18n.validation, validations.params) }}
    </b-form-invalid-feedback>
    <b-form-invalid-feedback v-else :id="'invalid-' + feedback_id">
      {{ $t("VALIDATION.INVALID", { name: "" }) }}
    </b-form-invalid-feedback>
    <b-form-valid-feedback :id="'valid-' + feedback_id">
      {{ $t("VALIDATION.VALID") }}
    </b-form-valid-feedback>
  </div>
</template>

<script>
export default {
  name: "FormTextarea",
  inheritAttrs: false,
  props: {
    /**
     * Validation id of the error
     */
    feedback_id: String,
    /**
     * name of the input
     */
    name: String,
    /**
     * All Translation i18n keys used
     */
    /**
     * All Translation i18n keys used
     */
    i18n: {
      type: Object,
      default: () => ({})
    },
    /**
     * All validations rules and params
     */
    validations: {
      type: Object,
      default: () => ({})
    },
    /**
     * Validate State validation
     */
    validateState: {
      type: Boolean,
      default: null
    },
    /**
     * V-Model
     */
    value: {}
  },
  data() {
    return {};
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {
    setValue(value) {
      //  trigger a mutation, or dispatch an action
      this.$emit("input", value);
    }
  }
};
</script>
